<template>
	<div class=''>
		<!-- 首页大banner -->
		<div class="banner_swiper" id="banner">
			<img :src="bannerImg" class="w-max" style="object-fit: cover;">
			<!-- <el-carousel height="1024px" indicator-position='none'>
        <el-carousel-item v-for="item in 4" :key="item">

        </el-carousel-item>
      </el-carousel> -->
		</div>
		<!-- //首页大banner -->

		<!-- 文案 -->
		<div class="pt70 pb120">
			<div class="fs46 fw-600 text-center mb40">{{name}}</div>
			<!-- Swiper -->
			<!-- 			<div class="swiper-container">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(item,i) in swiperList" :key="i">
						<img :src="item.pcPic" class="w-max h-max">
					</div>
				</div>
				<div class="swiper-pagination"></div>
			</div> -->
			<el-carousel :interval="4000" type="card" :autoplay='false' @change='swiperChange'>
				<el-carousel-item v-for="(item,i) in swiperList" :key="i">
					<img :src="item.pcPic" class="w1000 h707">
				</el-carousel-item>
			</el-carousel>
		</div>


	</div>
</template>

<script>
	import bannerImgJs from '@/mixin/bannerImg'
	import { getUpHonors } from '@/api'

	import Swiper from 'swiper'
	import 'swiper/css/swiper.min.css'
	export default {
		name: 'honors',
		data() {
			return {
				bannerType: 4,
				swiperList: [],
				name: ''
			};
		},
		mixins: [bannerImgJs],
		components: {},

		created() {
			this.$store.commit('editRouterName', this.$route.name)
			//获取轮播图
			this.getUpHonors()
		},

		mounted() {

		},

		methods: {
			//获取轮播图
			async getUpHonors() {
				const res = await getUpHonors()
				this.swiperList = res.data
				this.name = res.data[0].productName
				// this.$nextTick(() => {
				// 	let that =this
				// 	new Swiper('.swiper-container', {
				// 		slidesPerView: 3,
				// 		spaceBetween: 30,
				// 		centeredSlides: true,
				// 		loop: true,
				// 		pagination: {
				// 			el: '.swiper-pagination',
				// 			clickable: true,
				// 		},
				// 		watchSlidesProgress: true,
				// 		on: {
				// 			transitionEnd : function() {
				// 				// console.log(111,this.realIndex)
				// 				that.name=that.swiperList[this.realIndex].productName
				// 			}
				// 		},
				// 	});
				// })
			},
			swiperChange(e) {
				this.name = this.swiperList[e].productName
			}
		}
	}
</script>
<style lang='scss' scoped>
	::v-deep .el-carousel__container {
		height: 707px;
	}
	::v-deep .el-carousel__indicators--outside {
		margin-top: 60px;
	}

	::v-deep .el-carousel__button {
		width: 80px;
		height: 12px;
	}

	img {
		max-width: none;
	}

	.swiper-container {
		width: 100%;
		height: 700px;
	}

	.swiper-slide {
		width: 700px;
		text-align: center;
		font-size: 18px;
		background: red;
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		transition: 300ms;
		transform: scale(0.72);
	}

	.swiper-slide-active,
	.swiper-slide-duplicate-active {
		transform: scale(1);
	}
</style>
